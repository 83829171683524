









































































import { Component, Vue } from 'vue-property-decorator';
import {BFormCheckbox, BFormGroup, BButton, BSpinner} from 'bootstrap-vue';
import { Entreprise } from '@/api/models/options/entreprises/entreprise';
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import { SecteurActivite } from '@/api/models/options/secteursActivites/secteurActivite';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import router from "@/router";

import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";

@Component({
  components: {
    BFormCheckbox,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    BSpinner
  }
})

export default class DetailsEntreprise extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;
  entreprise: Entreprise | null = null;  

  searchSecteursActivitesTotalCount = 0;
  searchSecteursActivitesItems: SecteurActivite[] = [];


  loading = true;

  async created() {
    await this.getEntrepriseById()
  }

  async searchSecteursActivites(params: any) {
    if (!params || params?.reset) {      
      this.searchSecteursActivitesItems = this.searchSecteursActivitesItems?.filter(e => e.id == this.entreprise?.secteurActiviteId);
      this.searchSecteursActivitesTotalCount = this.searchSecteursActivitesItems.length
    }

    await this.$http.ressifnet.secteursActivites
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 40,
        params?.search ?? ""
      )
      .then((response: PaginatedList<SecteurActivite>) => {
        this.searchSecteursActivitesTotalCount = response.totalCount;
        this.searchSecteursActivitesItems = this.searchSecteursActivitesItems!.concat(response.items.filter(ri => !this.searchSecteursActivitesItems!.some(lsi => lsi.id == ri.id)))
      });
  }

  async getEntrepriseById(){
    await this.$http.ressifnet.entreprises
      .getById(this.$route.params.id, false)
      .then((entreprise : any) => {
        this.entreprise = entreprise
        this.loading = false
      });
  }

  async activateChangeAllCrl(event: any) {
    if (event) {
      await this.changeAllCrl();
    }
  }

  async changeAllCrl() {
    this.$bvModal
      .msgBoxConfirm('Êtes-vous sûr de vouloir autoriser tout les utilisateurs Crl ?', {
        title: 'Confirmation',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          var data = { allCrl: true }
          await this.$http.ressifnet.entreprises
            .putAllCrl(this.$route.params.id, data)
            .then(
              (reponse: any) => {
                successAlert.fire({
                  title: "Modification de l'autorisation",
                  text: "Modification effectué avec succès",
                });
            },
            (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            }
          );
        }
        else {
          return;
        }
      })
    
  }

  async updateEntreprise() {
    await this.$http.ressifnet.entreprises
      .put(this.$route.params.id, this.entreprise)
      .then(
        (reponse: any) => {
          successAlert.fire({
            title: "Modification de l'entreprise",
            text: "Modification effectué avec succès",
          });
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }

  async askDeleteEntreprise() {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer l\'entreprise ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          await this.confirmDeleteEntreprise(this.$route.params.id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteEntreprise(id: string) {
    await this.$http.ressifnet.entreprises
      .delete(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une entreprise",
            text: "Suppression effectuée avec succès",
          });
          router.push({
          name: "admin-entreprises",
        });
        },
        (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
      )
  }
}
